function drawImage() {
	const image = document.querySelector( 'img#the-image' ) as HTMLImageElement | null;
	if ( !image ) {
		return;
	}

	const imageScale = document.querySelector( 'input#image-scale' ) as HTMLInputElement | null;
	if ( !imageScale ) {
		return;
	}

	const imageScaleValue = parseInt( imageScale.value, 10 );
	if ( isNaN( imageScaleValue ) ) {
		return;
	}

	const imageX = document.querySelector( 'input#image-x' ) as HTMLInputElement | null;
	if ( !imageX ) {
		return;
	}

	const imageXValue = parseInt( imageX.value, 10 );
	if ( isNaN( imageXValue ) ) {
		return;
	}

	const imageY = document.querySelector( 'input#image-y' ) as HTMLInputElement | null;
	if ( !imageY ) {
		return;
	}

	const imageYValue = parseInt( imageY.value, 10 );
	if ( isNaN( imageYValue ) ) {
		return;
	}

	image.style.width = `${imageScaleValue}%`;
	image.style.height = 'auto';
	image.style.transform = `translate(${imageXValue - 50}%, ${imageYValue - 50}%)`;
}

function previewFile() {
	const image = document.querySelector( 'img#the-image' ) as HTMLImageElement | null;
	if ( !image ) {
		return;
	}

	const fileInput = document.querySelector( 'input[type="file"]#picture' ) as HTMLInputElement | null;
	if ( !fileInput ) {
		return;
	}

	const reader = new FileReader();

	reader.addEventListener( 'load', () => {
		// convert image file to base64 string
		image.src = reader.result?.toString() || '';
	}, false );

	if ( fileInput && fileInput.files && 0 < fileInput.files.length ) {
		reader.readAsDataURL( fileInput.files[0] );
	}
}

function drawViewFinder() {
	const theSVG = document.querySelector( '#the-svg' );
	const pathA = document.querySelector( '#path-a' );
	const pathB = document.querySelector( '#path-b' );

	const outerAspectRatio = parseFloat( ( document.querySelector( 'select#outer-aspect-ratio' ) as HTMLSelectElement | null )?.value || '' );
	if ( !outerAspectRatio || isNaN( outerAspectRatio ) ) {
		return;
	}

	const innerLeftXValue = parseInt( ( document.querySelector( 'input#inner-left-x' ) as HTMLInputElement | null )?.value || '', 10 );
	if ( !innerLeftXValue || isNaN( innerLeftXValue ) ) {
		return;
	}

	const innerRightXValue = parseInt( ( document.querySelector( 'input#inner-right-x' ) as HTMLInputElement | null )?.value || '', 10 );
	if ( !innerRightXValue || isNaN( innerRightXValue ) ) {
		return;
	}

	const innerTopYValue = parseInt( ( document.querySelector( 'input#inner-top-y' ) as HTMLInputElement | null )?.value || '', 10 );
	if ( !innerTopYValue || isNaN( innerTopYValue ) ) {
		return;
	}

	const innerBottomYValue = parseInt( ( document.querySelector( 'input#inner-bottom-y' ) as HTMLInputElement | null )?.value || '', 10 );
	if ( !innerBottomYValue || isNaN( innerBottomYValue ) ) {
		return;
	}

	const innerLeftX = Math.min(
		innerLeftXValue,
		innerRightXValue
	);

	const innerTopY = Math.min(
		innerTopYValue,
		innerBottomYValue
	);

	const innerRightX = Math.max(
		innerLeftXValue,
		innerRightXValue
	);

	const innerBottomY = Math.max(
		innerTopYValue,
		innerBottomYValue
	);


	const outerMin = 0;
	const outerMax = 100;

	const outer = {
		top: {
			left: {
				x: outerMin,
				y: outerMin,
			},
			right: {
				x: outerMax,
				y: outerMin,
			},
		},
		bottom: {
			left: {
				x: outerMin,
				y: outerAspectRatio * outerMax,
			},
			right: {
				x: outerMax,
				y: outerAspectRatio * outerMax,
			},
		},
	};

	const inner = {
		top: {
			left: {
				x: innerLeftX,
				y: innerTopY * outerAspectRatio,
			},
			right: {
				x: innerRightX,
				y: innerTopY * outerAspectRatio,
			},
		},
		bottom: {
			left: {
				x: innerLeftX,
				y: innerBottomY * outerAspectRatio,
			},
			right: {
				x: innerRightX,
				y: innerBottomY * outerAspectRatio,
			},
		},
	};

	const viewBox = `0 0 ${outer.bottom.right.x} ${outer.bottom.right.y}`;
	theSVG?.setAttribute( 'viewBox', viewBox );

	pathA?.setAttribute( 'd', `
				M ${outer.top.left.x},${outer.top.left.y}
				L ${outer.top.right.x},${outer.top.right.y}
				L ${outer.bottom.right.x},${outer.bottom.right.y}
				L ${outer.bottom.left.x},${outer.bottom.left.y}
				L ${outer.top.left.x},${outer.top.left.y}
			` );

	pathB?.setAttribute( 'd', `
				M ${outer.top.left.x},${outer.top.left.y}
				L ${inner.top.left.x},${inner.top.left.y}

				M ${outer.top.right.x},${outer.top.right.y}
				L ${inner.top.right.x},${inner.top.right.y}

				M ${outer.bottom.right.x},${outer.bottom.right.y}
				L ${inner.bottom.right.x},${inner.bottom.right.y}

				M ${outer.bottom.left.x},${outer.bottom.left.y}
				L ${inner.bottom.left.x},${inner.bottom.left.y}

				M ${inner.top.left.x},${inner.top.left.y}
				L ${inner.top.right.x},${inner.top.right.y}
				L ${inner.bottom.right.x},${inner.bottom.right.y}
				L ${inner.bottom.left.x},${inner.bottom.left.y}
				L ${inner.top.left.x},${inner.top.left.y}
			` );
}

let randomData: Array<{
	imageSrc: string;
	imageScale: number;
	imageX: number;
	imageY: number;
	innerLeftX: number;
	innerTopY: number;
	innerRightX: number;
	innerBottomY: number;
}> = [];

let lastRandomNumber = 0;
function randomNumber( min:number, max:number ):number {
	let random;
	do {
		random = Math.floor( Math.random() * ( ( max - min ) ) + min );
	} while ( random === lastRandomNumber );

	lastRandomNumber = random;

	return random;
}

window.addEventListener( 'load', () => {
	randomData = [
		{
			// @ts-expect-error
			imageSrc: window._backflash_frame_tool_asset_url + '/sites/2963/2021/11/Divers-3.jpg?w=1000&q=60&dpr=1',
			imageScale: 22,
			imageX: -37,
			imageY: -17,
			innerLeftX: 32,
			innerTopY: 61,
			innerRightX: 78,
			innerBottomY: 31,
		},
		{
			// @ts-expect-error
			imageSrc: window._backflash_frame_tool_asset_url + '/sites/2963/2021/11/Eastpak-4.png?w=1000&q=60&dpr=1',
			imageScale: 34,
			imageX: 68,
			imageY: -24,
			innerLeftX: 17,
			innerTopY: 48,
			innerRightX: 27,
			innerBottomY: 68,
		},
		{
			// @ts-expect-error
			imageSrc: window._backflash_frame_tool_asset_url + '/sites/2963/2021/11/Wrangler-2.png?w=1000&q=60&dpr=1',
			imageScale: 35,
			imageX: 13,
			imageY: 30,
			innerLeftX: 58,
			innerTopY: 29,
			innerRightX: 13,
			innerBottomY: 70,
		},
		{
			// @ts-expect-error
			imageSrc: window._backflash_frame_tool_asset_url + '/sites/2963/2021/11/PaulBoudens.png?w=1000&q=60&dpr=1',
			imageScale: 33,
			imageX: -68,
			imageY: 62,
			innerLeftX: 28,
			innerTopY: 55,
			innerRightX: 42,
			innerBottomY: 29,
		},
		{
			// @ts-expect-error
			imageSrc: window._backflash_frame_tool_asset_url + '/sites/2963/2021/09/mrhenry-gezicht-small.png?w=1000&q=60&dpr=1',
			imageScale: 17,
			imageX: 21,
			imageY: -35,
			innerLeftX: 65,
			innerTopY: 43,
			innerRightX: 80,
			innerBottomY: 75,
		},
	];

	drawViewFinder();

	document.querySelector( 'input#image-scale' )?.removeEventListener( 'change', drawImage );
	document.querySelector( 'input#image-x' )?.removeEventListener( 'change', drawImage );
	document.querySelector( 'input#image-y' )?.removeEventListener( 'change', drawImage );
	document.querySelector( 'input#inner-left-x' )?.removeEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#inner-top-y' )?.removeEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#inner-right-x' )?.removeEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#inner-bottom-y' )?.removeEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#picture' )?.removeEventListener( 'change', previewFile );

	document.querySelector( 'input#image-scale' )?.addEventListener( 'change', drawImage );
	document.querySelector( 'input#image-x' )?.addEventListener( 'change', drawImage );
	document.querySelector( 'input#image-y' )?.addEventListener( 'change', drawImage );
	document.querySelector( 'input#inner-left-x' )?.addEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#inner-top-y' )?.addEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#inner-right-x' )?.addEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#inner-bottom-y' )?.addEventListener( 'change', drawViewFinder );
	document.querySelector( 'input#picture' )?.addEventListener( 'change', previewFile );

	document.querySelector( '#randombutton' )?.removeEventListener( 'click', randomizeFrameToolData );
	document.querySelector( '#randombutton' )?.addEventListener( 'click', randomizeFrameToolData );
} );

function randomizeFrameToolData() {
	const index = randomNumber( 0, ( randomData.length ) );
	const theImage = document.querySelector( 'img#the-image' ) as HTMLImageElement | null;
	if ( !theImage ) {
		return;
	}

	theImage.removeAttribute( 'src' );

	assignValueToInputIfExists( 'image-scale', randomData[index].imageScale );
	assignValueToInputIfExists( 'image-x', randomData[index].imageX );
	assignValueToInputIfExists( 'image-y', randomData[index].imageY );
	assignValueToInputIfExists( 'inner-left-x', randomData[index].innerLeftX );
	assignValueToInputIfExists( 'inner-top-y', randomData[index].innerTopY );
	assignValueToInputIfExists( 'inner-right-x', randomData[index].innerRightX );
	assignValueToInputIfExists( 'inner-bottom-y', randomData[index].innerBottomY );

	drawViewFinder();
	drawImage();

	theImage.src = randomData[index].imageSrc;
}

function assignValueToInputIfExists( id: string, value: number ) {
	const el = document.querySelector( `input#${id}` ) as HTMLInputElement | null;
	if ( !el ) {
		return;
	}

	el.value = value.toString();
	el.setAttribute( 'value', value.toString() );
}
